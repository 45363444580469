@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Sans+Arabic:wght@100;200;300;400;500;600;700&display=swap');

:root {
  --bg-color: #fff;
  --p-color: #d5d5d5;

  --main-color2: #52A2BD;
  --second-color: #344A53;
  /* للتفاصيل والعناصر الزخرفية  */
  --third-color: #97AFB9;
  /* للتفاصيل والعناصر الزخرفية  */
  --four-color: #B08CB6;
  /* لتحديد النصوص أو الأزرار البارزة  */
  --five-color: #7B5981;


  --main-color: ;
  --main-color-opacity-100: rgba(var(--main-color), 1);
  --main-color-opacity-50: rgba(var(--main-color), 0.5);
  --main-color-opacity-35: rgba(var(--main-color), 0.35);
  --main-color-opacity-20: rgba(var(--main-color), 0.2);
  --main-color-opacity-1: rgba(var(--main-color), 0.1);
  --main-color-opacity-05: rgba(var(--main-color), 0.05);
}

* {
  border: none;
  box-sizing: border-box;
  font-family: "Epilogue", sans-serif;
  list-style: none;
  margin: 0;
  outline: none;
  padding: 0;
  text-decoration: none;
  scroll-behavior: smooth;
  text-transform: capitalize;
}

html {
  /* overflow: hidden; */
}

::-webkit-scrollbar {
  width: 6px !important;
}

::-webkit-scrollbar-thumb {
  background: var(--second-color) !important;
  /* border-radius: 12px; */
  transition: all 0.3s ease;
  box-shadow: none !important;
}

::-webkit-scrollbar-track {
  background: var(--bg-light-color);
}

body {
  margin: 0;
  font-family: 'IBM Plex Sans Arabic', sans-serif;
  background-color: var(--bg-color);
  overflow-x: clip;
  color: #000;
  scroll-behavior: smooth;
  line-height: 1.3;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

::selection {
  -webkit-text-fill-color: initial !important;
  background-clip: initial !important;
  -webkit-background-clip: initial !important;
  background-color: var(--second-color);
  background-image: none !important;
  color: #f5f5f5 !important;
}

a {
  color: #fff;
}

section {
  position: relative;
}

.container {
  width: 85%;
  margin: auto;
  max-width: 1375px;
}

/* header */
header {
  position: fixed;
  top: 0;
  width: 100%;
  padding: 1rem;
  color: #fff;
  background-color: var(--main-color-opacity-100);
  z-index: 10;
  box-shadow: 0 6px 14px #0000001a;
}

header.active {
  box-shadow: 0 6px 14px #0000001a;
}

header .links li {
  display: flex;
  align-items: center;
}

header .links li a {
  font-size: 1.2rem;
  font-weight: 500;
  position: relative;
  padding-bottom: .4rem;
  color: var(--p-color);
}

header .logo {
  width: 15rem;
  align-items: center;
  display: flex;
}

header .logo img {
  width: 70px !important;
}


header .links li a::before {
  position: absolute;
  content: '';
  width: 0%;
  height: .1rem;
  background: #fff;
  bottom: 0;
  border-radius: 1rem;
  transition: all .3s ease-in-out;
}

header .links li a.active::before {
  width: 100%;
}

header .links li a:hover {
  color: #fff;
}

header .links li a.active {
  color: #fff;
}

header .links li a:hover::before {
  width: 100%;
}

header .container {
  display: flex;
  justify-content: space-between;
}

header .menu {
  display: none;
}

header .container ul {
  display: flex;
  width: 50%;
  max-width: 15rem;
  gap: 1.5rem;
}

header .langLi {
  display: flex;
  align-items: center;
  text-align: center;
}

header .langLi button {
  padding: .5rem;
  cursor: pointer;
  border-radius: .2rem;
}

section {
  padding-top: 2rem;
  padding-bottom: 2rem;
}

/* start home */
.home {
  height: 85vh !important;
  position: relative;
  /* background: url('../src//assest//bg.jpg'), var(--bg-color);
  background-repeat: no-repeat;
  background-size: cover;
  overflow: hidden; */
  color: #fff;
  background-color: var(--main-color-opacity-100);
}

/* .home .bg img {
  position: absolute;
  width: 100%;
  height: 100% !important;
  left: 0;
  top: 0;
  z-index: -1;
  opacity: .9;
} */

.home .container {
  height: 100%;
  display: flex;
  align-items: center;
  padding-top: 75px;
  padding-bottom: 65px;
}

.home .container .content {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
}

.home .container .content .box-text {
  flex: 1;
}

.home .container .content .box-text span {
  color: var(--five-color);
}

.home .container .content p span {
  color: #ffca6a !important;
  font-weight: 500;
  display: block;
}

.btn {
  padding: .5rem 2rem;
  font-size: 1rem;
  font-weight: 600;
  background: var(--five-color);
  color: #fff;
  margin: 2rem 0;
  border-radius: 0.2rem;
  position: relative;
  transition: all .2s ease-in-out;
  -webkit-border-radius: 0.2rem;
  -moz-border-radius: 0.2rem;
  -ms-border-radius: 0.2rem;
  -o-border-radius: 0.2rem;
}

.btn:hover {
  background: var(--four-color);
  cursor: pointer;
}

.btn:hover::before {
  left: 10px;
  top: 10px;
  width: 10%;
  height: 10%;
}

.home .container .content .box-image {
  flex: 1;
  text-align: center;
  position: relative;
}

.home .box-text h2 {
  font-size: 2.7rem;
}

.home .box-text p {
  font-size: 1.2rem;
  padding-top: 2rem;
  color: #fffdfd;
}

.home .box-image img {
  width: 25rem;
}

.home .box-image::before {
  position: absolute;
  content: '';
}

/* end home */


/* start services */
/* contact social */
.services .contact-menu {
  position: fixed;
  left: 1rem;
  bottom: 3rem;
  z-index: 3;
  direction: rtl;
}

.services .main {
  display: none;
  flex-direction: column;
  gap: 0.5em;
  margin-bottom: -3.5rem;
}

.services .main.active {
  display: flex;
}

.services .up {
  display: flex;
  flex-direction: row-reverse;
  gap: 0.5em;
}

.services .down {
  display: flex;
  flex-direction: row;
  gap: 0.5em;
}

.services .card1,
.services .card2,
.services .card3,
.services .card4 {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.services .card1 {
  width: 60px;
  height: 60px;
  outline: none;
  border: none;
  background: white;
  border-radius: 90px 5px 5px 5px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
  transition: .2s ease-in-out;
}

.services .instagram {
  color: #cc39a4;
}

.services .card2 {
  width: 60px;
  height: 60px;
  outline: none;
  border: none;
  background: white;
  border-radius: 5px 90px 5px 5px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
  transition: .2s ease-in-out;
}

.services .facebook {
  font-size: 1.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #03A9F4;
}

.services .card3 {
  width: 60px;
  height: 60px;
  outline: none;
  border: none;
  background: white;
  border-radius: 5px 5px 5px 90px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
  transition: .2s ease-in-out;
}

.services .whatsapp {
  font-size: 1.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #25D366;
}

.services .card4 {
  width: 60px;
  height: 60px;
  outline: none;
  border: none;
  transition: .2s ease-in-out;
  background: var(--second-color);
}

.services .support {
  font-size: 1.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  fill: #fff;
  animation: support 2s infinite ease-in-out;
}

.services .card1:hover {
  cursor: pointer;
  scale: 1.1;
  background-color: #cc39a4;
}

.services .card1:hover .instagram {
  color: white;
}

.services .card2:hover {
  cursor: pointer;
  scale: 1.1;
  background-color: #03A9F4;
}

.services .card2:hover .facebook {
  color: white;
}

.services .card3:hover {
  cursor: pointer;
  scale: 1.1;
  background-color: #25D366;
}

.services .card3:hover .whatsapp {
  color: white;
}

.services .card4:hover {
  cursor: pointer;
  scale: 1.1;
}

.support-menu {
  position: relative;
  background: hsl(0deg 3.95% 90.95% / 20%);
  border-radius: 20px;
  box-shadow: 0 0 35px 0 rgba(0, 0, 0, .1);
  padding: 1rem;
  margin-right: auto;
}

/* contact social */


.services .title {
  color: #fff;
  font-size: 3rem;
}

.services .cards,
.blogs .cards {
  display: grid;
  gap: 3rem !important;
  grid-template-columns: repeat(auto-fit, minmax(15rem, 1fr));
  margin: 2rem auto;
  width: 87%;
}

.services .cards .card,
.blogs .cards .card {
  position: relative;
  text-align: center;
  margin: auto;
  width: 17rem;
  height: 20rem;
  background: #fff;
  border-top-left-radius: 1rem;
  border-top-right-radius: 1rem;
}

.services .cards .card .imgService,
.blogs .cards .card .imgService {
  width: 17rem;
  height: 10rem;
  border-top-left-radius: 1rem;
  border-top-right-radius: 1rem;
}

.services .cards .card .content {
  padding: .5rem;
}

.services .cards .card .content h3 {
  font-size: 1rem;
  color: var(--main-color-opacity-100);
}

.services .cards .card .content p {
  margin-top: 1rem;
  color: var(--second-color);
  font-size: .8rem;
}

.services .cards .card .btnService {
  font-size: .8rem;
  padding: .5rem;
  border-radius: .2rem;
  color: #fff;
  background: var(--five-color);
  cursor: pointer;
  bottom: 5px;
  position: absolute;
  text-align: center;
  left: 50%;
  transform: translateX(-50%);
  transition: all .3s ease-in-out;
  -webkit-transition: all .3s ease-in-out;
  -moz-transition: all .3s ease-in-out;
  -ms-transition: all .3s ease-in-out;
  -o-transition: all .3s ease-in-out;
}

.services .cards .card .btnService:hover {
  background-color: var(--four-color);
}

/* end services */

/* service */
.service,
.blogs {
  position: relative;
  margin-top: 7rem;
}

.service .container {
  text-align: center;
}

.service .content {
  max-width: 40rem;
  margin: auto;
}

.service .content h2 {
  color: var(--main-color-opacity-100);
}

.service .content .image {
  margin: 1rem 0;
}

.service .content img {
  width: 100%;
  max-width: 30rem;
  margin: auto;
  border-radius: .2rem;
}

.service .content .desc p {
  font-size: medium;
  color: var(--second-color);
  text-align: start;
}

/* service */


/* customers */
.customers {
  position: relative;
  background: #F6F6F9;
}

section .title {
  text-align: center;
  font-size: 2rem;
  color: var(--main-color-opacity-100);
}

.customers .container .box-content {
  background: #d5d5d52e;
  border-radius: 1rem;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: center;
  min-height: 18rem;
}

.customers .container .box-content .image {
  width: 25rem;
  margin: auto;
  position: relative;
}

.customers .container .box-content .image::before {
  position: absolute;
  content: '';
  width: 100px;
  height: 110px;
  background: url('../src/assest/logo2.png');
  z-index: 1;
  opacity: .5;
  left: -50px;
  bottom: -40px;
}

.customers .container .box-content .image img {
  width: 100%;
  border-radius: 1rem;
  position: absolute;
  top: -13rem;
}

.customers .container .box-content .content {
  width: 50%;
}

.customers .container .box-content .content p {
  font-size: 1.4rem;
  max-width: 30rem;
}

.customers .container .cards .card-swiper img {
  width: 100px;
  max-height: 70px;
}

.customers .swiper-wrapper {
  align-items: center;
  margin: auto;
  text-align: center;
  user-select: none;
}

.customers .customer {
  text-align: center;
  padding-top: 2rem;
  padding-bottom: 2rem;
  cursor: ew-resize;
}

.customers .customer .cards {
  width: 100%;
  /* max-width: 45rem; */
  margin: auto;
}

section .description {
  text-align: center;
  font-size: 1.2rem;
  padding-bottom: 2rem;
  color: var(--second-color);
  width: 55%;
  margin: auto;
}

/* customers */

/* whyMe and benefit*/
.why-me,
.benefit {
  position: relative;
}

.why-me .cards,
.benefit .cards {
  display: grid;
  gap: 4rem !important;
  grid-template-columns: repeat(auto-fit, minmax(25rem, 1fr));
  margin: 2rem auto;
  width: 87%;
}

.why-me .cards .card,
.benefit .cards .card {
  text-align: center;
}

.why-me .card h3 {
  padding: 1rem 0;
  color: var(--four-color);
}

.benefit .card h3 {
  padding: 1rem 0;
  color: var(--main-color-opacity-100);
}

.why-me .card p {
  width: 70%;
  margin: auto;
  font-size: .8rem;
  color: var(--second-color);
}

/* whyMe */


/* approach */
.approach,
.services,
.contact {
  position: relative;
  background-color: var(--main-color-opacity-100);
}

.approach .container,
.contact .container {
  height: 100%;
  display: flex;
  align-items: center;
  padding-top: 40px;
  padding-bottom: 40px;
}

.approach .container .content,
.contact .container .content {
  display: flex;
  width: 80%;
  flex-wrap: wrap;
  margin: auto;
}

.approach .container .content .box-text,
.contact .container .content .box-text {
  flex: 1;
}

.approach .box-text h2,
.contact .box-text h2 {
  padding: 1rem 0 1rem;
  color: #fff;
  font-size: 3rem;
  text-align: center;
}

.approach .box-text h2 span,
.contact .box-text h2 span {
  color: var(--five-color);
}

.approach .cards {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.approach .cards .card {
  width: 70%;
  max-width: 20rem;
  display: flex;
  gap: 2rem;
  margin: auto;
}

.approach .cards .card .num {
  font-size: 2rem;
  color: var(--five-color);
  font-weight: 700;
}

.approach .cards .card .text h3 {
  padding-bottom: 1rem;
  color: #fff;
}

.approach .cards .card .text p {
  color: #fff;
  font-size: .8rem;
}

.approach .container .content .box-image,
.contact .container .content .box-image {
  flex: 1;
  text-align: center;
  position: relative;
  margin: auto;
}

/* approach */


/* Projects */
.projects {
  position: relative;
}

.projects .cards {
  display: grid;
  gap: 4rem !important;
  grid-template-columns: repeat(auto-fit, minmax(15rem, 1fr));
  margin: 2rem auto;
  width: 87%;
}

.projects .cards .card {
  position: relative;
  margin: auto;
  width: 17rem;
  /* height: 25rem; */
  padding: 1rem;
  background: var(--third-color);
  background: var(--main-color-opacity-100);
  border-radius: .5rem;
  text-align: center;
  cursor: help;
  transition: all .3s ease-in-out;
  -webkit-transition: all .3s ease-in-out;
  -moz-transition: all .3s ease-in-out;
  -ms-transition: all .3s ease-in-out;
  -o-transition: all .3s ease-in-out;
}

.projects .cards .card .imgProject {
  width: 15rem;
  height: 8rem;
}

.projects .cards .card:hover {
  background: var(--four-color);
}

.projects .cards .card h3 {
  font-size: 1rem;
  color: var(--five-color);
  padding-bottom: 1rem;
}

.projects .cards .card h4 {
  padding: .2rem 0;
  color: var(--second-color);
}

.projects .cards .card h4 span {
  display: inline-block;
  color: #fff;
  font-size: .8rem;
  font-weight: 500;
}

/* Projects */

/* contact */
.contact {
  position: relative;
}

.contact .form {
  width: 80%;
  text-align: center;
  margin: auto;
}

.contact .form form {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.contact .form .inputGroup {
  display: flex;
  flex-direction: column;
}

.contact .form .inputGroup label {
  color: #fff;
}

.contact .form .inputGroup input,
textarea {
  font-size: .8rem;
  padding: .8rem;
  border-radius: .2rem;
  background: var(--p-color);
  transition: all .2s linear;
  -webkit-transition: all .2s linear;
  -moz-transition: all .2s linear;
  -ms-transition: all .2s linear;
  -o-transition: all .2s linear;
}

.contact .form .inputGroup input:hover,
textarea:hover,
.contact .form .inputGroup input:focus,
textarea:focus {
  background: #fff;
}

.contact .options {
  font-size: .8rem;
  text-align: start;
  color: var(--second-color);
}

.contact .options h4 {
  font-size: 1rem;
  padding-bottom: .5rem;
}

.contact .options .groupOption {
  align-items: center;
  display: flex;
  /* justify-content: center; */
}

.contact .options .groupOption label {
  margin: 0 .2rem;
}



/* contact */

/* blogs */
.blogs .imgService {
  border-radius: 0 !important;
  -webkit-border-radius: 0 !important;
  -moz-border-radius: 0 !important;
  -ms-border-radius: 0 !important;
  -o-border-radius: 0 !important;
}

.blogs .content h3 {
  font-size: 1rem;
  color: black;
}

.blogs .content p {
  font-size: .8rem;
  color: var(--second-color);
}

.spanDate {
  color: var(--five-color) !important;
  font-size: .8rem;
  margin-top: 1rem;
  display: flex;
  justify-content: center;
}

/* blogs */

/* start footer */
footer {
  background-color: var(--second-color);
  color: #fff;
  padding: 2rem 0px 1rem;
}

footer .top-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  align-items: center;
  margin: auto;
}

footer .top-content .box {
  line-height: 2;
}

footer .top-content .box.center {
  padding-top: 2rem;
}

footer .top-content .box.bottom li {
  list-style: initial;
}

footer .center-content {
  padding-top: 1rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

footer .center-content img {
  width: 65px;
}

footer .center-content h3 {
  font-weight: 300;
  color: var(--p-color);
}

footer .center-content h3 a {
  font-weight: 800;
  color: #fff;
}

footer .bottom-content {
  display: flex;
  justify-content: space-between;
  padding-top: 1rem;
  border-top: 1px solid #cccccc3d;
}

.icons {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: .5rem;
}

.icons i {
  font-size: 1.3rem;
}

.contact .icons i {
  font-size: 2rem;
}

.icons i:hover {
  color: var(--main-color-opacity-100);
}

.contact .icons i:hover {
  color: var(--second-color);
}

footer .bottom-content .copyright {
  color: var(--p-color);
  margin: auto;
  text-align: center;
  font-size: .7rem;
}

/* start footer */


/* start media */
@media (max-width:1270px) {
  .home .box-text h2 {
    font-size: 2.7rem;
  }

  .home .box-text p {
    font-size: 1.2rem;
  }

  .about .poster .poster-text {
    flex-direction: column;
  }

  .about .poster .btn {
    margin-top: 2rem;
  }
}

@media (max-width:990px) {
  .home .container .content .box-text {
    width: 100%;

  }

  .approach .box-text {
    width: 100%;

  }

  .home .box-text h2 {
    font-size: 2.5rem;
  }

  .home {
    height: auto !important;
  }

  .home .container .content .box-image {
    margin: auto;
  }

  .home .container .content {
    padding-top: 4rem;
    flex-direction: column;
  }

  .approach .container .content,
  .contact .container .content {
    padding-top: 4rem;
    flex-direction: column-reverse;
  }

  .approach .container .content img,
  .contact .container .content img {
    width: 15rem;
  }

  .services .cards {
    grid-template-columns: repeat(auto-fit, minmax(15rem, 1fr));
  }
}

@media (max-width:895px) {
  .reviews {
    padding-top: 18rem;
  }

  .reviews .container .box-content {
    min-height: 30rem;
    justify-content: center;
  }

  .reviews .container .box-content .content {
    width: 85%;
  }

  .reviews .container .box-content .image {
    width: 18rem;
  }

  .reviews .container .box-content .image img {
    top: -8rem;
  }


  footer .top-content .box {
    line-height: 2;
    width: 100%;
    margin: auto;
    text-align: center;
  }

  footer li {
    list-style: none !important;
  }
}

@media (max-width:730px) {
  header .links li {
    margin: auto;
  }

  header .links li a {
    padding-bottom: 0;
  }

  .home .box-text h2 {
    font-size: 1.5rem !important;
  }

  .home .btnTextHome {
    font-size: .8rem !important;
  }

  .home .box-text p {
    font-size: 1.2rem;
  }

  .home .box-image img {
    width: 15rem;
  }

  /* .home .container .content .box-text {
    min-width: 200px;
  } */

  header .menu {
    display: initial;
  }

  header .logo {
    width: auto;
  }

  header .container .menu {
    display: block;
    width: 50px;
    height: 50px;
    position: absolute;
    left: 5%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    gap: .5rem;
    top: 8px;
  }

  header .container.active ul {
    margin: auto;
    text-align: center;
    line-height: 2.5;
  }

  header .container .menu.active .span1 {
    transform: rotate(45deg);
  }

  header .container .menu.active .span2 {
    display: none;
  }

  header .container .menu.active .span3 {
    transform: rotate(120deg);
  }

  header .container .menu span {
    width: 3rem;
    height: 4px;
    background: #fff;
    border-radius: 1rem;
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
  }

  header .container .menu .span2 {
    width: 2rem;
  }


  header .container.active {
    flex-direction: column-reverse;
    /* height: 40vh; */
  }

  header .container.active .logo {
    margin: auto;
  }

  header .container ul,
  .language {
    top: -7rem;
    position: absolute;
  }


  header .container.active ul {
    flex-direction: column;
    position: static;
  }

  header .container.active .language {
    flex-direction: column;
    position: static;
  }

  header .container .button {
    width: 120px;
    height: 45px;
    top: -5rem;
    position: absolute;
  }

  .home .container .content {
    padding-top: 4rem;
  }

  .customers .customer h4 {
    width: 100%;
    font-size: 1rem;
  }

  .services .title {
    font-size: 2rem;
  }

  .approach .cards .card {
    width: 100%;
  }

  .approach .box-text h2,
  .contact .box-text h2 {
    font-size: 2rem;
  }

  .why-me .cards,
  .benefit .cards {
    grid-template-columns: repeat(auto-fit, minmax(15rem, 1fr));
  }

  .contact .form {
    width: 100%;
  }

  footer .top-content .box {
    padding-top: 2rem;
  }

  footer .copyright span {
    display: inline-block;
  }

}

/* end media */